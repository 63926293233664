import PaginateNotes from '~/apollo/queries/notes/paginate';
import NoteQuery from '~/apollo/queries/notes/one';
import CreateNote from '~/apollo/mutations/notes/create';
import UpdateNote from '~/apollo/mutations/notes/update';

import { PARSE_APPOINTMENT } from '../appointments';
import Editor from '~/components/form/Editor';

const PARSE = (note, { id, $asUTC, $$moment } = {}) => {
	if (note.created_at) note.created_at = $asUTC(note.created_at);
	if (note.updated_at) note.updated_at = $asUTC(note.updated_at);
	if (note.appointment) note.appointment = PARSE_APPOINTMENT(note.appointment, { $$moment });
	delete note.__typename;
	return { ...note, id: note.id || id };
};

export const state = () => ({
	pagination: { current: 1, limit: 25, total: 0 },
	filters: {},
	notes: [],
});

export const getters = {
	GET_TABLES_COLUMNS() {
		return [
			{ data: 'title', title: 'global.title' },
			{ slot: 'created_at', title: 'dates.created-at' },
			{ slot: 'updated_at', title: 'dates.updated-at' },
		];
	},
	GET_PAGINATION({ pagination }) {
		return pagination;
	},
	GET_FILTERS({ filters }) {
		return filters;
	},
	GET_NOTES({ notes }) {
		return notes;
	},
};

export const actions = {
	async GET_NOTE(_, { id, title, filename, updated_at, patientId }) {
		return await this.$useQuery(NoteQuery, { id }, async ({ notes_by_pk }) => {
			const $note = PARSE(notes_by_pk, { id, $asUTC: this.$asUTC, $$moment: this.$$moment });
			$note.title = title;
			$note.filename = filename;
			if ($note.updated_at[0] !== updated_at[0] || $note.updated_at[1] !== updated_at[1])
				this.$useFile('remove', filename);
			const payload = { name: 'note.json', module: 'patients/notes', patientId };
			$note.content = filename
				? await this.$useFile('getContent', filename, payload)
				: { blocks: [] };
			if ($note.content) return $note;
		});
	},
	async SET_NOTES({ getters, commit }) {
		const where = { ...getters['GET_FILTERS'] },
			{ current, limit } = getters['GET_PAGINATION'],
			filters = { offset: (current - 1) * limit, limit, where };
		filters.where.is_deleted = { _eq: false };
		await this.$useQuery(PaginateNotes, filters, (data) => {
			commit('SET_PAGINATION', { total: data.notes_aggregate.aggregate.count });
			data = data.notes.map((n) => PARSE(n, this));
			commit('SET_NOTES', data);
		});
	},
	async CREATE_NOTE({ state, commit, rootGetters }, set) {
		return Editor.methods.isValueValid(set.content, false, async (valid, content) => {
			if (!valid) return this.$useNotify('error', this.$t('notes.content-invalid'));
			const afterUpload = async (filename) => {
				set.filename = filename;
				if (filename) this.$useFile('set', filename, { file: payload.object, content });
				set.clinic_id = rootGetters['clinic/GET_CLINIC'].id;
				delete set.content;
				return await this.$useMutation(CreateNote, { object: set }, (data) => {
					delete set.patient_id, delete set.clinic_id;
					set.updated_at = this.$asUTC(data.insert_notes_one.updated_at);
					set.created_at = [...set.updated_at];
					set = { ...set, id: data.insert_notes_one.id };
					commit('SET_NOTES', [set, ...state.notes]);
					this.$useBindAction('created', 'notes.the-note');
					return { ...set, content };
				});
			};
			if (!content.blocks.length) return afterUpload(null);
			const payload = {
				module: 'patients/notes',
				object: this.$useFile('generate', content, 'note.json'),
				'patient-id': set.patient_id,
			};
			return await this.$useAxios('upload', payload, ({ objectId }) => afterUpload(objectId));
		});
	},
	async UPDATE_NOTE({ state, commit }, { id, set }) {
		return Editor.methods.isValueValid(set.content, false, async (valid, content) => {
			if (!valid) return this.$useNotify('error', this.$t('notes.content-invalid'));

			// Upload the new content only if changed (We compare it with the last cached one)
			let uploaded = true,
				filenameChange = false;
			if (!content.blocks.length) {
				this.$useFile('remove', set.filename);
				set.filename = null;
				filenameChange = true;
			} else if (
				!set.filename ||
				!Editor.methods.areEqual(content, this.$useFile('getContent', set.filename))
			) {
				const payload = {
					module: 'patients/notes',
					object: this.$useFile('generate', content, 'note.json'),
					'patient-id': set.patient_id,
					'previous-object-id': set.filename,
				};
				if (!set.filename) delete payload['previous-object-id'];
				uploaded = await this.$useAxios('upload', payload, async ({ objectId }) => {
					filenameChange = set.filename !== objectId;
					set.filename = objectId;
					this.$useFile('set', set.filename, { file: payload.object, content });
					return true;
				});
			}

			if (uploaded) {
				delete set.content, delete set.patient_id;
				const filename = set.filename;
				if (!filenameChange) delete set.filename;
				return await this.$useMutation(UpdateNote, { id, set }, (data) => {
					set.updated_at = this.$asUTC(data.update_notes_by_pk.updated_at);
					const current = state.notes
						.filter((n) => n.id === id)
						.map((n) => ({ ...n, title: set.title, updated_at: [...set.updated_at] }));
					commit('SET_NOTES', current.concat(state.notes.filter((n) => n.id !== id)));
					this.$useBindAction('updated', 'notes.the-note');
					return { ...set, filename, content, id };
				});
			}
		});
	},
	async DELETE_NOTE({ state, dispatch, commit }, { id, filename, refresh }) {
		return await this.$useMutation(UpdateNote, { id, set: { is_deleted: true } }, () => {
			this.$useFile('remove', filename);
			const list = state.notes.filter((n) => n.id !== id);
			commit('SET_NOTES', list);
			this.$useBindAction('deleted', 'notes.the-note');
			if (refresh) dispatch('SET_NOTES');
		});
	},
};

export const mutations = {
	SET_PAGINATION(state, payload) {
		state.pagination = { ...state.pagination, ...payload };
	},
	SET_FILTERS(state, payload) {
		state.filters = payload;
	},
	SET_NOTES(state, payload) {
		state.notes = payload;
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
