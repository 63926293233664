import PaginatePrescriptions from '~/apollo/queries/prescriptions/paginate';
import PrescriptionQuery from '~/apollo/queries/prescriptions/one';
import ItemsQuery from '~/apollo/queries/prescriptions/items';
import CreatePrescription from '~/apollo/mutations/prescriptions/create';
import UpdatePrescription from '~/apollo/mutations/prescriptions/update';
import UpdateItem from '~/apollo/mutations/prescriptions/update-item';
import DeletePrescription from '~/apollo/mutations/prescriptions/delete';

const PARSE = (prescription, { id } = {}) => {
	prescription = { ...prescription, indications: prescription.indications || '' };
	if (prescription.prescriptions_items)
		prescription.items = prescription.prescriptions_items.map((it) => {
			it = { ...it, medication: it.code_ean };
			delete it.code_ean, delete it.__typename;
			return it;
		});
	delete prescription.prescriptions_items, delete prescription.__typename;
	return { ...prescription, ...prescription, id: prescription.id || id };
};

export const state = () => ({
	prescriptions: {},
	pagination: { _: { current: 1, limit: 25, total: 0 } },
	filters: {},
});

export const getters = {
	GET_TABLES_COLUMNS() {
		return [{ slot: 'date', title: 'dates.date' }];
	},
	GET_PRESCRIPTIONS({ prescriptions }) {
		return prescriptions;
	},
	GET_PAGINATION({ pagination }) {
		return pagination;
	},
	GET_FILTERS({ filters }) {
		return filters;
	},
};

export const actions = {
	async GET_PRESCRIPTION(_, { id }) {
		return await this.$useQuery(PrescriptionQuery, { id }, (data) => {
			if (data.prescriptions_by_pk) return PARSE(data.prescriptions_by_pk, { id });
		});
	},
	async SET_PRESCRIPTIONS({ getters, commit }, { tableName = 'default' } = {}) {
		const $pagination = getters['GET_PAGINATION'];
		commit('SET_PAGINATION', { [tableName]: { ...$pagination._, ...$pagination[tableName] } });

		const where = getters['GET_FILTERS'][tableName],
			{ current, limit } = getters['GET_PAGINATION'][tableName],
			filters = { offset: (current - 1) * limit, limit, where };
		await this.$useQuery(PaginatePrescriptions, filters, (data) => {
			const total = data.prescriptions_aggregate.aggregate.count;
			commit('SET_PAGINATION', { [tableName]: { total } });
			commit('SET_PRESCRIPTIONS', { [tableName]: data.prescriptions.map(PARSE) });
		});
	},
	async CREATE_PRESCRIPTION({ state, commit }, { set, patientId }) {
		set = { ...set, prescriptions_items: { data: set.items } };
		delete set.items;
		return await this.$useMutation(CreatePrescription, { object: set }, (data) => {
			set.id = data.insert_prescriptions_one.id;
			delete set.patient_id, delete set.prescriptions_items;
			const list = [set, ...state.prescriptions[patientId]];
			commit('SET_PRESCRIPTIONS', {
				[patientId]: list.sort((a, b) => (a.date > b.date ? -1 : 1)),
			});
			this.$useBindAction('created', 'prescriptions.the-prescription');
			const ids = data.insert_prescriptions_one.prescriptions_items;
			return [set.id, ids.map(({ id }) => id)];
		});
	},
	async UPDATE_PRESCRIPTION({ state, commit }, { id, set, showMessage, patientId }) {
		let validMutation = true,
			$set = { date: set.date, indications: set.indications || null },
			payload = { id, set: $set, itemsToDelete: { prescription_id: { _eq: id } } },
			fields = ['code_ean', 'times', 'times_label', 'duration', 'duration_label', 'indications'],
			onError = () => (showMessage = validMutation = showMessage && this.$useNotify('error')),
			compareItemsToUpdate = ({ prescriptions_items: list }) => {
				const $list = set.items.filter((e) => list.some((el) => e.id === el.id));
				payload.itemsToDelete.id = { _nin: $list.map((e) => e.id) };
				return Promise.all(
					list.map(($el) => {
						const el = $el.id && $list.find((e) => e.id === $el.id);
						if (!el || !fields.some((k) => el[k] !== $el[k])) return;
						const set = fields.reduce((a, k) => ({ ...a, [k]: el[k] }), {});
						return this.$useMutation(UpdateItem, { id: el.id, set }, () => {}, onError);
					})
				);
			},
			onSuccess = ({ insert_prescriptions_items: list }) => {
				if (showMessage) this.$useBindAction('updated', 'prescriptions.the-prescription');
				const $list = (state.prescriptions[patientId] || []).map((pre) => {
					return pre.id === id ? { ...pre, date: set.date } : pre;
				});
				commit('SET_PRESCRIPTIONS', {
					[patientId]: $list.sort((a, b) => (a.date > b.date ? -1 : 1)),
				});
				return [id, list?.returning?.map(({ id }) => id) || []];
			};

		// Fetch the current list from the database to determine which items need updating or deletion and perform the mutations loop to update the modified items.
		await this.$useQuery(ItemsQuery, { id }, compareItemsToUpdate, onError);
		if (!validMutation) return;

		// Perform the mutation to update the prescription, delete the removed items, insert the new ones and return their UUIDs.
		payload.itemsToAdd = set.items
			.filter((e) => !e.id)
			.map((el) => fields.reduce((a, k) => ({ ...a, [k]: el[k] }), { prescription_id: id }));
		delete set.items;
		return await this.$useMutation(UpdatePrescription, payload, onSuccess, onError);
	},
	async DELETE_PRESCRIPTION({ state, commit, dispatch }, { id, refresh, patientId }) {
		await this.$useMutation(DeletePrescription, { id }, () => {
			const list = state.prescriptions[patientId].filter?.((pre) => pre.id !== id) || [];
			commit('SET_PRESCRIPTIONS', { [patientId]: list });
			this.$useBindAction('deleted', 'prescriptions.the-prescription');
			if (refresh) dispatch('SET_PRESCRIPTIONS', { tableName: patientId });
		});
	},
};

export const mutations = {
	SET_PRESCRIPTIONS(state, payload) {
		state.prescriptions = { ...state.prescriptions, ...payload };
	},
	SET_PAGINATION(state, payload) {
		const $payload = { ...state.pagination };
		Object.entries(payload).map(([key, value]) => ($payload[key] = { ...$payload[key], ...value }));
		state.pagination = $payload;
	},
	SET_FILTERS(state, payload) {
		state.filters = { ...state.filters, ...payload };
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
