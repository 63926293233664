export const state = () => ({
	firstRoute: true,
	routes: null,
	activeRoute: 1,
	payload: undefined,
	subscriptions: {},
});

export const getters = {
	GET_APP_INFO() {
		return {
			name: 'Clinikdna',
			businessWebsite: 'https://clinikdna.com/',
			privacyPolicy: 'https://clinikdna.com/privacy-policy',
			termsConditions: 'https://clinikdna.com/terms-and-conditions',
			security: 'https://clinikdna.com/security',
		};
	},
	GET_ROUTES({ routes }) {
		return routes;
	},
	GET_ACTIVE_ROUTE({ activeRoute }) {
		return activeRoute;
	},
	GET_STORE_PAYLOAD({ payload }) {
		return payload;
	},
	GET_SUBSCRIPTIONS({ subscriptions }) {
		return subscriptions;
	},
};

export const actions = {
	async nuxtServerInit({ dispatch }, { redirect, route }) {
		const email = this.$useCookie('auth_email'),
			isScreencastPage = (route?.query?.r || route?.path) === '/screencast';
		if (/^[a-z0-9._-]+@[a-z]+\.[a-z]+$/.test(String(email).toLowerCase()))
			await dispatch('user/INIT', {
				email,
				onError: (error, isScreencastEmail) => {
					if (!error) return;
					this.$useRemoveToken();
					if (isScreencastEmail && !isScreencastPage) redirect('/screencast');
					else if (!isScreencastEmail) {
						this.$useRemoveCookie('auth_email');
						redirect('/sign-in');
					}
				},
				onSuccess(_, isScreencastEmail) {
					if (isScreencastEmail && !isScreencastPage) redirect('/screencast');
				},
			});
	},
	STORE_PAYLOAD({ getters, commit }, payload) {
		// A getter and setter in the same time.
		const oldPayload = getters.GET_STORE_PAYLOAD;
		commit('SET_STORE_PAYLOAD', payload);
		return oldPayload;
	},
	async CLEAR_STORE({ commit }) {
		await this.$useCloseSubscription?.(); // Unsubscribe from all subscriptions
		this.$useFile('clear'); // Clear all stored files and revoke all created URL
		commit('CLEAR_STORE');
		commit('appointments/CLEAR_STORE');
		commit('clinic/CLEAR_STORE');
		commit('invoices/CLEAR_STORE');
		commit('patients/CLEAR_STORE');
		commit('notes/CLEAR_STORE');
		commit('playlists/CLEAR_STORE');
		commit('prescriptions/CLEAR_STORE');
		commit('queue/CLEAR_STORE');
		commit('settings/CLEAR_STORE');
		commit('analytics/CLEAR_STORE');
		commit('team/CLEAR_STORE');
		commit('treatments/CLEAR_STORE');
	},
};

export const mutations = {
	SET_ROUTES(state, payload) {
		if (payload) payload = payload.map((route, key) => ({ ...route, key }));
		state.routes = payload;
	},
	SET_ACTIVE_ROUTE(state, key) {
		state.activeRoute = key;
	},
	SET_STORE_PAYLOAD(state, payload) {
		state.payload = payload;
	},
	SET_FIRST_ROUTE(state) {
		state.firstRoute = false;
	},
	SET_SUBSCRIPTION(state, { key, status }) {
		state.subscriptions = { ...state.subscriptions, [key]: status };
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
