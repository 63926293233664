import PaginatePatients from '~/apollo/queries/patients/paginate';
import PatientQuery from '~/apollo/queries/patients/one';
import PatientsSearch from '~/apollo/queries/patients/all';
import PatientInfoQuery from '~/apollo/queries/patients/one-info';
import AddPatient from '~/apollo/mutations/patients/add';
import UpdatePatient from '~/apollo/mutations/patients/update';
import DeletePatient from '~/apollo/mutations/patients/delete';

const PARSE = (patient, { id } = {}) => {
	patient = { ...patient };
	const contact = patient.contact?.[0] || { ...patient.contact },
		{ full_name, fname, lname, fname_ar, lname_ar, gender, phones, emails } = contact;
	patient.email = emails?.[0] || patient.email || '';
	contact.sex = gender;
	contact.phone = phones?.[0] || '';
	contact.full_name = full_name || `${fname} ${lname}`.trim();
	contact.full_name_ar = `${fname_ar || ''} ${lname_ar || ''}`.trim();
	if (contact.dob === null) contact.dob = '';
	if (contact.address === null) contact.address = '';
	if (contact.extra_details === null) contact.extra_details = '';
	delete contact.gender, contact.phones, delete contact.emails, delete patient.contact;
	delete contact.__typename, delete patient.__typename;
	return { ...contact, ...patient, id: patient.id || id };
};

export { PARSE as PARSE_PATIENT };

export const state = () => ({
	pagination: { current: 1, limit: 25, total: 0 },
	filters: {},
	patient: null,
	patients: [],
	searchPatients: [],
});

export const getters = {
	GET_TABLES_COLUMNS() {
		return [
			{ data: 'fname', title: 'auth.fname' },
			{ data: 'lname', title: 'auth.lname' },
			{ slot: 'phone', title: 'auth.phone' },
		];
	},
	GET_PAGINATION({ pagination }) {
		return pagination;
	},
	GET_FILTERS({ filters }) {
		return filters;
	},
	GET_PATIENT({ patient }) {
		return patient;
	},
	GET_PATIENTS({ patients }) {
		return patients;
	},
	GET_SEARCH_PATIENTS({ searchPatients }) {
		return searchPatients;
	},
};

export const actions = {
	async SET_PATIENT({ commit }, id) {
		await this.$useQuery(PatientQuery, { id }, ({ patients_by_pk }) => {
			if (!patients_by_pk || !patients_by_pk?.contact?.[0]) return;
			commit('SET_PATIENT', PARSE(patients_by_pk, { id }));
		});
	},
	async SET_PATIENTS({ getters, commit }) {
		const where = getters['GET_FILTERS'],
			{ current, limit } = getters['GET_PAGINATION'],
			filters = { offset: (current - 1) * limit, limit, where };
		await this.$useQuery(PaginatePatients, filters, (data) => {
			commit('SET_PAGINATION', { total: data.patients_aggregate.aggregate.count });
			commit('SET_PATIENTS', data.patients.map(PARSE));
		});
	},
	async SET_SEARCH_PATIENTS({ commit }, where) {
		return await this.$useQuery(PatientsSearch, { where }, ({ patients }) => {
			commit('SET_SEARCH_PATIENTS', patients.map(PARSE));
			return patients.map(PARSE);
		});
	},
	async GET_ADDITIONAL_PATIENT_INFO(_, id) {
		return await this.$useQuery(PatientInfoQuery, { id }, (data) =>
			PARSE({ contact: data.patients_contacts })
		);
	},
	async ADD_PATIENT({ rootGetters, dispatch }, { set, push = true }) {
		const { privacy_policy_agreement } = set;
		set.dob = set.dob || null;
		set.address = set.address || null;
		set.extra_details = set.extra_details || null;
		set.emails = set.email ? [set.email] : null;
		set.phones = set.phone ? [set.phone] : [];
		set.gender = set.sex;
		['email', 'phone', 'sex', 'privacy_policy_agreement'].map((k) => delete set[k]);
		const object = {
			clinic_id: rootGetters['clinic/GET_CLINIC'].id,
			privacy_policy_agreement,
			registered_at: this.$$moment().format('YYYY-MM-DD'),
			contact: { data: set },
		};
		await this.$useMutation(AddPatient, { object }, ({ insert_patients_one: { id } }) => {
			const name = `<b>${set.fname} ${set.lname}</b>`;
			this.$useBindAction('added', 'patient-records.the-patient', name);
			if (push) {
				dispatch('STORE_PAYLOAD', { isNewPatient: true }, { root: true });
				this.$router.replace(`/patients/${id}`);
			}
		});
	},
	async UPDATE_PATIENT(_, { id, set }) {
		const patientSet = { privacy_policy_agreement: set.privacy_policy_agreement };
		set.dob = set.dob || null;
		set.address = set.address || null;
		set.extra_details = set.extra_details || null;
		set.emails = set.email ? [set.email] : null;
		set.phones = set.phone ? [set.phone] : [];
		set.gender = set.sex;
		['email', 'phone', 'sex', 'privacy_policy_agreement'].map((k) => delete set[k]);
		await this.$useMutation(UpdatePatient, { id, set: patientSet, contact_set: set }, () =>
			this.$useBindAction('updated', 'patient-records.the-patient')
		);
	},
	async DELETE_PATIENT({ dispatch }, { id, refresh }) {
		await this.$useMutation(DeletePatient, { id }, () => {
			this.$useBindAction('deleted', 'patient-records.the-patient');
			if (refresh) dispatch('SET_PATIENTS');
		});
	},
};

export const mutations = {
	SET_PAGINATION(state, payload) {
		state.pagination = { ...state.pagination, ...payload };
	},
	SET_FILTERS(state, payload) {
		state.filters = payload;
	},
	SET_PATIENT(state, payload) {
		state.patient = payload;
	},
	SET_PATIENTS(state, payload) {
		state.patients = payload;
	},
	SET_SEARCH_PATIENTS(state, payload) {
		state.searchPatients = payload;
	},
	CLEAR_STORE(stateInstance) {
		Object.entries(state()).map(([key, value]) => (stateInstance[key] = value));
	},
};
